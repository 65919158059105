import { ChangeDetectionStrategy } from '@angular/core'
import { Component, Input, OnInit } from '@angular/core'
import { Venue } from '../../state/venue/venue'

@Component({
	selector: 'app-venue-card',
	templateUrl: './venue-card.component.html',
	styleUrls: ['./venue-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VenueCardComponent implements OnInit {
	@Input() venue!: Venue
	constructor() {}

	ngOnInit(): void {}

	get venuePicture() {
		return this.venue.partner?.images?.thumb[0]?.coverUrl
	}

	//Lógica duplicada em venue.reducer.ts
	get benefit() {
		const points = this.venue.benefits.find(benefit => benefit.type === 'points')
		const exception = points.exceptions?.find((v: any) => v.venueId === this.venue._id)
		if (exception) {
			return exception
		}
		return points
	}

	//Lógica duplicada em venue.reducer.ts
	get dessert() {
		const dessert = this.venue.benefits.find(benefit => benefit.type === 'dessert')
		return dessert
	}
}
