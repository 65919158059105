import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { ActionsSubject, Store } from '@ngrx/store'
import { State } from 'src/app/state/app.state'
import { getSelectedDessert, getSelectedPoints, getVenue } from '../../state/venue/venue.reducers'
import * as AvailabilityActions from '../../state/reservation/reservation.actions'
import * as VenueActions from '../../state/venue/venue.actions'
import { ActivatedRoute, Router } from '@angular/router'
import {
	CHOOSE_DATE,
	CHOOSE_PARTYSIZE,
	CHOOSE_SECTION,
	getErrorAvailability,
	getAvailabilities,
	getReservationTime,
	getSchedules,
	getSections,
	getSelectedDate,
	getSelectedReservationTime,
	getSelectedPartySize,
	getSelectedSection,
	isLoadingAvailabililities,
	isLoadingConfirmReservation,
} from '../../state/reservation/reservation.reducers'
import { combineLatest, filter, map, Observable, Subscription, take, tap } from 'rxjs'
import { ofType } from '@ngrx/effects'
import { ModalService } from 'src/app/component/modal/modal.service'
import { getClient } from 'src/app/state/client/client.reducers'
import { Item } from 'src/app/lib/select-box/select-box.component'
import { Availability, Schedules, Section } from 'src/app/state/reservation/reservation'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { Title } from '@angular/platform-browser'

@Component({
	selector: 'app-reservation',
	templateUrl: './reservation.component.html',
	styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit, OnDestroy, AfterViewInit {
	// Loadings
	loadingAvailabilities$!: Observable<boolean>
	loadingConfirmReservation$ = this.store.select(isLoadingConfirmReservation)

	// Client
	client$ = this.store.select(getClient)

	// Venue
	venue$ = this.store.select(getVenue).pipe(
		filter(value => Boolean(value)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Reserva`))
	)
	selectedPoints$ = this.store.select(getSelectedPoints)
	selectedDessert$ = this.store.select(getSelectedDessert)

	// Error
	error$ = this.store.select(getErrorAvailability)

	// DATE
	selectedDate$ = this.store.select(getSelectedDate)
	availabilitiesDatesItems$ = this.store.select(getAvailabilities).pipe(
		filter(availability => Boolean(availability?.length)),
		map(
			availabilities =>
				[{ reservationDay: CHOOSE_DATE }, ...(availabilities as Availability[])] as Availability[]
		),
		map(availabilities =>
			availabilities.map(av => ({
				name: this.formatDate(av.reservationDay),
				value: av.reservationDay,
			}))
		)
	)

	// SECTIONS
	sectionsItems$ = this.store.select(getSections).pipe(
		filter(value => Boolean(value?.length)),
		map(section => [{ label: CHOOSE_SECTION }, ...(section as Section[])]),
		map(section =>
			section.map(sec => ({
				name: sec.label,
				value: sec.label,
			}))
		)
	)
	selectedSection$ = this.store.select(getSelectedSection)

	// SCHEDULES // PARTYSIZE
	schedulesItems$ = this.store.select(getSchedules).pipe(
		filter(value => Boolean(value?.length)),
		map(schedule => [{ partySize: CHOOSE_PARTYSIZE }, ...(schedule as Schedules[])]),
		map(schedule =>
			schedule?.map(sch => ({
				name: this.formatSchedule(sch.partySize.toString()),
				value: sch.partySize.toString(),
			}))
		)
	)
	selectedSchedule = this.store.select(getSelectedPartySize)

	// RESERVATION TIMES
	reservationTimes$ = this.store.select(getReservationTime)
	selectedReservationTime$ = this.store.select(getSelectedReservationTime)
	clickedTime = false

	times$ = this.reservationTimes$.pipe(
		filter(res => Boolean(res)),
		map((res: any) => {
			return {
				firstTime: res[0].reservationTime,
				lastTime: res[res?.length - 1].reservationTime,
			}
		})
	)

	vmTimes$ = combineLatest([this.reservationTimes$, this.selectedReservationTime$, this.times$]).pipe(
		map(([reservationTimes, selectedReservationTime, times]) => ({
			reservationTimes,
			selectedReservationTime,
			times,
		}))
	)

	formIsInvalid$ = combineLatest([
		this.selectedDate$,
		this.selectedSection$,
		this.selectedSchedule,
		this.selectedReservationTime$,
	]).pipe(
		map(([date, section, schedule, reservationTime]) => {
			return !Boolean(date && section && schedule && reservationTime)
		})
	)

	sub = new Subscription()
	modalsId = {
		modalNotReservationId: 'modalNotReservationId',
		modalFailure: 'modalFailureId',
		modalRegisterCard: 'modalRegisterCardId',
		modalTerms: 'modalTermsId',
	}

	venueId!: string

	constructor(
		private store: Store<State>,
		private route: ActivatedRoute,
		private router: Router,
		private actions$: ActionsSubject,
		private modalService: ModalService,
		private title: Title
	) {}

	ngOnInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('id') as string
		this.store.dispatch(AvailabilityActions.resetReservationState())
		this.store.dispatch(VenueActions.loadOneVenue({ venueId: this.venueId }))

		// Confirm Reservation Success
		this.sub.add(
			this.actions$
				.pipe(ofType(AvailabilityActions.confirmReservationSuccess))
				.subscribe(() => this.router.navigate([`/restaurantes/${this.venueId}/reserva-sucesso`]))
		)

		// Confirm Reservation Failure
		this.sub.add(
			this.actions$
				.pipe(ofType(AvailabilityActions.confirmReservationFailure))
				.subscribe(() => this.router.navigate([`/restaurantes/${this.venueId}/reserva-erro`]))
		)
	}

	ngAfterViewInit(): void {
		// FIX BUG:ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.loadingAvailabilities$ = this.store.select(isLoadingAvailabililities)
		}, 0)
		this.sub.add(
			this.error$.pipe(filter(value => Boolean(value))).subscribe(() => {
				this.modalService.open(this.modalsId.modalNotReservationId)
			})
		)

		// Has card selected?
		this.sub.add(
			this.client$.subscribe({
				next: client => {
					if (!client.selectedCard) {
						this.modalService.open(this.modalsId.modalRegisterCard)
					} else {
						this.store.dispatch(AvailabilityActions.loadAvailabilities({ venueId: this.venueId }))
					}
				},
			})
		)
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

	dateSelected(date: Item) {
		this.store.dispatch(AvailabilityActions.setSelectedDate({ selectedDate: date.value }))
	}

	sectionSelected(section: Item) {
		this.store.dispatch(AvailabilityActions.setSection({ selectedSectionLabel: section.value }))
	}

	scheduleSelected(partysize: Item) {
		this.store.dispatch(AvailabilityActions.setSchedule({ selectedPartySize: partysize.value }))
	}

	timeSelected(time: string) {
		this.store.dispatch(AvailabilityActions.setReservationTime({ selectedReservationTime: time }))
	}

	confirmReservation() {
		this.store.dispatch(AvailabilityActions.confirmReservation())
	}

	// Need to be arrow function to be passed as callback on @Input() children
	resetState = () => {
		this.store.dispatch(AvailabilityActions.resetReservationState())
	}

	formatDate(dateString: string) {
		if (dateString === CHOOSE_DATE) {
			return dateString
		}
		// Back returns in YYYY-MM-DD format
		const [year, month, day] = dateString.split('-')
		const date = new Date(+year, +month - 1, +day)
		const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
		}).format(date)
		const captalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
		return captalizeFirstLetter(dateFormatted)
	}

	formatSchedule(partysize: string) {
		if (partysize === CHOOSE_PARTYSIZE) {
			return partysize
		}
		if (+partysize === 1) {
			return `Mesa para 1 pessoa`
		}
		return `Mesa para ${partysize} pessoas`
	}

	openTerms() {
		this.modalService.open(this.modalsId.modalTerms)
	}
}
