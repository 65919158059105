<div class="page">
	<div class="page__header">
		<section class="confirm-order__header">
			<div class="confirm-order__wrapper wrapper">
				<p class="confirm-order__subtitle subtitle">
					Check-in realizado<br />
					com sucesso!
				</p>
			</div>
		</section>
	</div>
	<div class="page__body">
		<div class="confirm-order__wrapper wrapper" *ngIf="vm$ | async as vm">
			<section class="confirm-order__section section">
				<div class="confirm-order__date">
					<p class="confirm-order__date__day">
						{{ vm.checkinDate?.day | date : 'd' }}
					</p>
					<p class="confirm-order__date__month">
						{{ vm.checkinDate?.day | date : 'MMM' | replace : '[.]' : '' }}
					</p>
				</div>

				<section class="confirm-order__ticket">
					<app-ticket label="Restaurante" [data]="vm.venue?.name"></app-ticket>
					<app-ticket label="Horário" [data]="vm.checkinDate?.time"></app-ticket>
				</section>
			</section>

			<!-- BENEFÍCIOS -->
			<ng-container class="venue__benefit" *ngIf="selectedDessert$ | async as dessert">
				<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
				<br /><br />
			</ng-container>
			<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
				<app-benefit [value]="benefit.value"></app-benefit>
			</ng-container>
		</div>
	</div>
	<div class="page__footer">
		<div class="confirm-order__wrapper wrapper">
			<button class="confirm-order__button buttonLight" routerLink="/minhas-reservas">
				Ir para pagamento
			</button>
		</div>
	</div>
</div>
