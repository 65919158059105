<div class="page">
	<div class="page__body" *ngIf="selectedOrder$ | async as selectedOrder">
		<div class="orderVenue__wrapper wrapper">
			<section class="orderVenue__item">
				<div class="orderVenue__item__left">
					<p class="orderVenue__item__title">{{ selectedOrder.venue.name }}</p>
					<p class="orderVenue__item__text">{{ selectedOrder.venue.address | formatAddress }}</p>

					<div class="orderVenue__item__bottom">
						<div class="orderVenue__tab">
							<p class="orderVenue__tab__item">
								{{ selectedOrder.intent === 'checkin' ? 'Checkin' : 'Reserva de mesa' }} às
								{{ selectedOrder.reservationTime }}
							</p>
						</div>
						<!-- TODO: after azure functions being working, update here based on status order/payment -->
						<!-- <a class="orderVenue__item__link" (click)="openModalCancel()"> Cancelar reserva </a> -->
					</div>
				</div>
				<div class="orderVenue__item__right">
					<div class="orderVenue__date">
						<p class="orderVenue__date__day">{{ selectedOrder.reservationDay | date : 'dd' }}</p>
						<p class="orderVenue__date__month">
							{{ selectedOrder.reservationDay | date : 'MMM' | replace : '[.]' : ' ' }}
						</p>
					</div>
				</div>
			</section>
			<hr class="separator" />
			<section class="orderVenue__specifications">
				<ng-container *ngIf="!selectedOrder.payment">
					<app-ticket
						label="Mesa para"
						[data]="selectedOrder.partySize | formatPartysize"
					></app-ticket>
					<app-ticket label="Ambiente" [data]="selectedOrder.sectionLabel"></app-ticket>
				</ng-container>
				<ng-container *ngIf="selectedOrder.payment">
					<app-ticket
						label="Valor da conta"
						[data]="selectedOrder.payment.orderInitialValue / 100 | currency : 'BRL'"
					></app-ticket>
					<app-ticket
						label="Taxa de Serviço do Garçom"
						[data]="selectedOrder.payment.gratuityValue / 100 | currency : 'BRL'"
					></app-ticket>
					<app-ticket
						label="Valor total pago"
						[data]="selectedOrder.payment.orderTotalValue / 100 | currency : 'BRL'"
					></app-ticket>
					<app-ticket
						label="Pontos recebidos"
						data="{{ selectedOrder.payment.orderTotalPoints.toString() }} pontos"
						[tooltip]="true"
						[days]="1"
					></app-ticket>
					<app-ticket
						label="Horário"
						[data]="selectedOrder.payment.paidAt | date : 'HH:mm:ss'"
					></app-ticket>
					<app-ticket label="Código de Pagamento" [data]="selectedOrder.payment.code"></app-ticket>
				</ng-container>
			</section>

			<hr class="separator" />

			<!-- BENEFÍCIOS -->
			<ng-container class="venue__benefit" *ngIf="selectedDessert$ | async as dessert">
				<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
				<br /><br />
			</ng-container>
			<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
				<app-benefit [value]="benefit.value"></app-benefit>
			</ng-container>
		</div>
	</div>
</div>

<app-modal [id]="modalCancelId" top="35px" [preventOutsideClickClose]="true">
	<app-modal-cancel
		[id]="modalCancelId"
		template="reservation"
		*ngIf="{ loading: loadingCancel$ | async } as obj"
		(cancelSubmitted)="cancelOrder()"
		[loading]="obj.loading"
	></app-modal-cancel>
</app-modal>
