<div class="page">
	<div class="page__header">
		<section class="rConfirm__header">
			<div class="rConfirm__wrapper wrapper">
				<p class="rConfirm__subtitle subtitle">
					Reserva realizada<br />
					com sucesso!
				</p>
			</div>
		</section>
	</div>
	<div class="page__body" *ngIf="vm$ | async as vm">
		<div class="rConfirm__wrapper wrapper">
			<section class="rConfirm__section section">
				<div class="rConfirm__date">
					<p class="rConfirm__date__day">{{ vm.confirmReservation.reservationDay | date : 'd' }}</p>
					<p class="rConfirm__date__month">
						{{ vm.confirmReservation.reservationDay | date : 'MMM' | replace : '[.]' : '' }}
					</p>
				</div>

				<section class="rConfirm__ticket">
					<app-ticket label="Restaurante" [data]="vm.venue?.name"></app-ticket>
					<app-ticket
						label="Mesa para"
						[data]="vm.confirmReservation.partySize | formatPartysize"
					></app-ticket>

					<app-ticket label="Ambiente" [data]="vm.confirmReservation.sectionLabel"></app-ticket>
					<app-ticket label="Horário" [data]="vm.confirmReservation.reservationTime"></app-ticket>
					<app-ticket label="Endereço" [data]="vm.venue?.address | formatAddress"></app-ticket>
				</section>
			</section>

			<!-- BENEFÍCIOS -->
			<ng-container class="venue__benefit" *ngIf="selectedDessert$ | async as dessert">
				<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
				<br /><br />
			</ng-container>
			<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
				<app-benefit [value]="benefit.value"></app-benefit>
			</ng-container>
		</div>
	</div>
	<div class="page__footer">
		<div class="rConfirm__wrapper wrapper">
			<button class="rConfirm__button button" [routerLink]="['/minhas-reservas']">
				Ver minhas reservas
			</button>
			<button class="rConfirm__buttonGhost buttonGhost" [routerLink]="['/restaurantes']">
				Listar restaurantes
			</button>
		</div>
	</div>
</div>
