import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, tap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/shared/helpers/prefix-title'
import { State } from 'src/app/state/app.state'
import { getConfirmReservation } from 'src/app/state/reservation/reservation.reducers'
import { getSelectedDessert, getSelectedPoints, getVenue } from 'src/app/state/venue/venue.reducers'

@Component({
	templateUrl: './reservation-confirm.component.html',
	styleUrls: ['./reservation-confirm.component.scss'],
})
export class ReservationConfirmComponent implements OnInit {
	venue$ = this.store.select(getVenue).pipe(
		filter(value => Boolean(value)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Reserva realizada com sucesso!`))
	)

	selectedPoints$ = this.store.select(getSelectedPoints)
	selectedDessert$ = this.store.select(getSelectedDessert)

	confirmReservation$ = this.store.select(getConfirmReservation)

	vm$ = combineLatest([this.venue$, this.confirmReservation$]).pipe(
		map(([venue, confirmReservation]) => ({ venue, confirmReservation }))
	)

	constructor(private router: Router, private store: Store<State>, private title: Title) {}

	ngOnInit(): void {}

	AddCardBtn() {
		this.router.navigate(['/carteria/adicionar-cartao'])
	}
}
