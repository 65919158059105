<div class="venue" [routerLink]="['/restaurantes', venue._id]">
	<div class="venue__header">
		<div class="venue__header__picture">
			<picture *ngIf="venuePicture">
				<source [srcset]="venuePicture | absolutePath | optimizeCardSrc" />
				<img
					[src]="venuePicture | absolutePath | optimizeCard"
					alt=""
					class="venue__header__img"
					loading="lazy"
				/>
			</picture>
		</div>
	</div>
	<div class="venue__content">
		<!-- NOME DO RESTAURANTE -->
		<h3 class="venue__title title">{{ venue.name }}</h3>

		<!-- ENDEREÇO DO RESTAURANTE -->
		<p class="venue__text">{{ venue.address | formatAddress }}</p>

		<!-- TIPO DE CULINÁRIA -->
		<ul class="venue__tab">
			<li class="venue__tab__item" *ngFor="let cousine of venue.cousines.slice(0, 3)">
				{{ cousine.name }}
			</li>
		</ul>

		<!-- BENEFÍCIOS -->
		<div class="venue__benefit benefit">
			<div>
				<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
				<app-benefit size="small" [value]="benefit.value"></app-benefit>
			</div>
			<div class="benefit__link">Saiba mais</div>
		</div>
	</div>
</div>
