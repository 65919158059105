import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import * as OrderActons from './order.actions'
import { OrderFromHistory, PaymentOrder } from './order'

export interface OrderState {
	orders: OrderFromHistory[] | null
	ordersToPay: OrderFromHistory[] | null
	selectedOrder: OrderFromHistory | null
	selectedPoints: { value: string } | null
	selectedDessert: { name: string } | null
	loadingOrderHistory: boolean
	paymentOrder: any | null
	loadingPayment: boolean
	errorOrder: string | null
	errorPayment: string | null
	loadingCancel: boolean
	errorCancel: string | null
	orderById: OrderFromHistory | null
	errorOrderById: string | null
	loadingOrderById: boolean
	hasPaymentReady: boolean
}

const initialState: OrderState = {
	orders: null,
	ordersToPay: null,
	selectedOrder: null,
	selectedPoints: null,
	selectedDessert: null,
	loadingOrderHistory: false,
	paymentOrder: null,
	loadingPayment: false,
	errorOrder: null,
	errorPayment: null,
	loadingCancel: false,
	errorCancel: null,
	orderById: null,
	loadingOrderById: false,
	errorOrderById: null,
	hasPaymentReady: false,
}

const getOrderFeatureState = createFeatureSelector<OrderState>('order')

export const loadingCancel = createSelector(getOrderFeatureState, state => state.loadingCancel)
export const loadingOrderHistory = createSelector(getOrderFeatureState, state => state.loadingOrderHistory)
export const loadingPayment = createSelector(getOrderFeatureState, state => state.loadingPayment)
export const getOrders = createSelector(getOrderFeatureState, state => state.orders)
export const getSelectedOrder = createSelector(getOrderFeatureState, state => state.selectedOrder)
export const getPaymentOrder = createSelector(getOrderFeatureState, state => state.paymentOrder)
export const getError = createSelector(getOrderFeatureState, state => state.errorOrder)
export const getErrorPayment = createSelector(getOrderFeatureState, state => state.errorPayment)
export const getSelectedPoints = createSelector(getOrderFeatureState, state => state.selectedPoints)
export const getSelectedDessert = createSelector(getOrderFeatureState, state => state.selectedDessert)

export const getOrderById = createSelector(getOrderFeatureState, state => state.orderById)
export const getErrorOrderById = createSelector(getOrderFeatureState, state => state.errorOrderById)

export const getOrdersCanPay = createSelector(getOrderFeatureState, state => state.ordersToPay)

export const getErrorCancel = createSelector(getOrderFeatureState, state => state.errorCancel)

export const getHasPaymentReady = createSelector(getOrderFeatureState, state => state.hasPaymentReady)

export const orderReducer = createReducer<OrderState>(
	initialState,
	on(OrderActons.setSelectedOrder, (state, action): OrderState => {
		const foundOrder = state.orders?.find(order => order._id === action.orderId)
		// In Theory, ORDER (not venue) has the right benefit (if venue has exception, order will be ok)
		const selectedPoints = foundOrder?.benefits.find(benefit => benefit.type === 'points')
		const selectedDessert = foundOrder?.benefits.find(benefit => benefit.type === 'dessert')
		return {
			...state,
			selectedOrder: foundOrder ?? null,
			selectedPoints: selectedPoints ?? null,
			selectedDessert: selectedDessert ?? null,
		}
	}),
	// on(OrderActons.getOrderHistory, (state): OrderState => {
	// 	return {
	// 		...state,
	// 		selectedOrder: null,
	// 	}
	// }),
	on(OrderActons.loadOrderHistory, (state): OrderState => {
		return {
			...state,
			selectedOrder: null,
			loadingOrderHistory: true,
		}
	}),
	on(OrderActons.loadOrderHistorySuccess, (state, action): OrderState => {
		return {
			...state,
			orders: action.orders,
			ordersToPay: action.orders.filter(order => {
				if (!order.venueHasPaymentConfig) return false
				return order.paymentStatus === 'READY' || order.paymentStatus === 'FAILED'
			}),
			loadingOrderHistory: false,
			errorOrder: '',
		}
	}),
	on(OrderActons.loadOrderHistoryFailure, (state, action): OrderState => {
		return {
			...state,
			orders: null,
			loadingOrderHistory: false,
			errorOrder: action.error,
		}
	}),
	on(OrderActons.setPaymentOrder, (state, action): OrderState => {
		return {
			...state,
			paymentOrder: action.paymentOrder,
		}
	}),
	on(OrderActons.createPayment, (state, action): OrderState => {
		return {
			...state,
			loadingPayment: true,
		}
	}),
	on(OrderActons.createPaymentSuccess, (state, action): OrderState => {
		return {
			...state,
			paymentOrder: {
				...state.paymentOrder,
				code: action.res.code,
				paidAt: action.res.paidAt,
				points: action.res.points,
				totalValue: action.res.orderTotalValue / 100,
			},
			loadingPayment: false,
			errorPayment: '',
		}
	}),
	on(OrderActons.createPaymentFailure, (state, action): OrderState => {
		return {
			...state,
			errorPayment: action.error,
			loadingPayment: false,
		}
	}),
	on(OrderActons.cancelOrder, (state): OrderState => {
		return {
			...state,
			orderById: null,
			loadingCancel: true,
		}
	}),
	on(OrderActons.cancelOrderSuccess, (state): OrderState => {
		return {
			...state,
			loadingCancel: false,
		}
	}),
	on(OrderActons.cancelOrderFailure, (state, action): OrderState => {
		return {
			...state,
			errorCancel: action.error,
			loadingCancel: false,
		}
	}),
	on(OrderActons.getOrderById, (state): OrderState => {
		return {
			...state,
			loadingOrderById: true,
		}
	}),
	on(OrderActons.getOrderByIdSuccess, (state, action): OrderState => {
		return {
			...state,
			orderById: action.order,
			loadingOrderById: false,
		}
	}),
	on(OrderActons.cancelOrderFailure, (state, action): OrderState => {
		return {
			...state,
			errorOrderById: action.error,
			loadingOrderById: false,
		}
	}),
	on(OrderActons.getOrdersPaymentReadySuccess, (state, action): OrderState => {
		return {
			...state,
			hasPaymentReady: action.hasPaymentReady,
		}
	}),
	on(OrderActons.cancelOrderFailure, (state): OrderState => {
		return {
			...state,
			hasPaymentReady: false,
		}
	})
)
