<div class="page">
	<div class="page__header">
		<div class="reservation__wrapper wrapper">
			<div class="reservation__namePage namePage">Reserva para</div>
			<div class="reservation__title title" *ngIf="venue$ | async as venue">{{ venue.name }}</div>
		</div>
	</div>

	<div class="page__body">
		<div class="reservation__wrapper wrapper">
			<!-- FORM -->
			<div class="reservation__form">
				<!-- LOADING -->
				<app-spinner *ngIf="loadingAvailabilities$ | async"></app-spinner>

				<!-- DATA -->
				<ch-select-box
					*ngIf="availabilitiesDatesItems$ | async as availabilitiesDates"
					[items]="availabilitiesDates"
					(itemWasSelected)="dateSelected($event)"
				></ch-select-box>

				<!-- AMBIENTE -->
				<ch-select-box
					*ngIf="sectionsItems$ | async as sections"
					[items]="sections"
					(itemWasSelected)="sectionSelected($event)"
				></ch-select-box>

				<!-- MESA PARA XX PESSOAS -->
				<ch-select-box
					*ngIf="schedulesItems$ | async as schedules"
					[items]="schedules"
					(itemWasSelected)="scheduleSelected($event)"
				></ch-select-box>

				<!-- HORÁRIOS -->
				<ng-container *ngIf="vmTimes$ | async as vmTimes">
					<div class="reservation__selectBox" tabindex="0" *ngIf="vmTimes.reservationTimes">
						<p class="reservation__selectBox__time__text">
							Exibindo horários disponíveis entre {{ vmTimes.times.firstTime }} e
							{{ vmTimes.times.lastTime }}
						</p>
						<div class="reservation__selectBox__time__options" tabindex="1">
							<label
								tabindex="1"
								class="reservation__selectBox__time__label"
								*ngFor="let rt of vmTimes.reservationTimes"
								(click)="timeSelected(rt.reservationTime)"
								[ngClass]="{
									'reservation__selectBox__time__label--active':
										rt.reservationTime === vmTimes.selectedReservationTime
								}"
							>
								{{ rt.reservationTime }}
							</label>
						</div>
					</div>
				</ng-container>
			</div>

			<!-- BENEFÍCIOS -->
			<ng-container class="venue__benefit" *ngIf="selectedDessert$ | async as dessert">
				<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
				<br /><br />
			</ng-container>
			<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
				<app-benefit [value]="benefit.value"></app-benefit>
			</ng-container>
		</div>

		<div class="terms">
			Ao confirmar a reserva, você está de acordo com o
			<span class="terms__btn" (click)="openTerms()"
				>Regulamento, Termos e Condições e Política de Privacidade.</span
			>
		</div>
	</div>

	<div class="page__footer">
		<div
			class="reservation__wrapper wrapper"
			*ngIf="{
				formIsInvalid: formIsInvalid$ | async,
				loading: loadingConfirmReservation$ | async
			} as obj"
		>
			<button
				class="reservation__button button"
				[ngClass]="{ 'button--disable': obj.formIsInvalid || obj.loading }"
				[disabled]="obj.formIsInvalid || obj.loading"
				(click)="confirmReservation()"
			>
				Confirmar reserva
				<app-spinner class="loading" *ngIf="obj.loading"></app-spinner>
			</button>
		</div>
	</div>
</div>

<app-modal [id]="modalsId.modalNotReservationId" top="25%" [preventOutsideClickClose]="true">
	<app-modal-failure
		[id]="modalsId.modalNotReservationId"
		template="somethingWentWrong"
		*ngIf="error$ | async as error"
		[message]="error"
		[cb]="resetState"
	></app-modal-failure>
</app-modal>

<app-modal [id]="modalsId.modalRegisterCard" top="25%" [preventOutsideClickClose]="true">
	<app-modal-register-card [id]="modalsId.modalRegisterCard"></app-modal-register-card>
</app-modal>

<app-modal [id]="modalsId.modalTerms" top="0%" bottom="0%" [preventOutsideClickClose]="false">
	<app-modal-terms [id]="modalsId.modalTerms"></app-modal-terms>
</app-modal>
