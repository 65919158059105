<div class="page">
	<div class="page__header">
		<div class="checkin__wrapper wrapper">
			<div class="checkin__namePage namePage">Check-in para</div>
			<div class="checkin__title title" *ngIf="venue$ | async as venue">{{ venue.name }}</div>
		</div>
	</div>

	<div class="page__body">
		<div class="checkin__wrapper wrapper">
			<!-- LOADING -->
			<app-spinner *ngIf="statusAvailability === 'pending'"></app-spinner>

			<ng-container *ngIf="statusAvailability === 'failure'">
				<ng-container *ngIf="errorAvailability$ | async as error">
					{{ error }}
				</ng-container>
			</ng-container>

			<ng-container *ngIf="statusAvailability === 'unavailable'">
				<app-box text="unavailableCheckin"></app-box>

				<div class="spacer"></div>
				<div class="divider"></div>
				<div class="spacer"></div>

				<h2 class="checkin__hour-title">Horários disponíveis para check-in</h2>
				<p class="checkin__hour-day" *ngIf="availabilities$ | async as availabilities">
					<span class="checkin__hour-day--strong">Horário de início:</span>
					{{ availabilities.startTime }} <br />
					<span class="checkin__hour-day--strong">Horário de término:</span>
					{{ availabilities.endTime }}
				</p>
			</ng-container>

			<ng-container *ngIf="statusAvailability === 'success'">
				<div class="checkin__data" *ngIf="client$ | async as clientState">
					<app-ticket
						label="Nome"
						data="{{ clientState.client.name }} {{ clientState.client.lastName }}"
					></app-ticket>
					<app-ticket label="Data" [data]="todayFormatted | capitalize"></app-ticket>
				</div>
				<div class="spacer"></div>
				<ch-select-box [items]="partySizes" (itemWasSelected)="setPartySize($event)"></ch-select-box>
				<div class="spacer"></div>
				<div class="checkin__benefit">
					<!-- BENEFÍCIOS -->
					<ng-container class="venue__benefit" *ngIf="selectedDessert$ | async as dessert">
						<app-benefit *ngIf="dessert" [value]="dessert.name" text="dessert"></app-benefit>
						<br /><br />
					</ng-container>
					<ng-container class="venue__benefit" *ngIf="selectedPoints$ | async as benefit">
						<app-benefit [value]="benefit.value"></app-benefit>
					</ng-container>
				</div>

				<div class="spacer"></div>
				<app-box text="enableCheckin"></app-box>

				<div class="spacer"></div>
				<form class="switch__container" [formGroup]="form">
					<label class="switch" for="slider">
						<input type="checkbox" id="slider" formControlName="confirm" />
						<span class="slider"></span>
					</label>
					<label for="slider">
						<span class="switch__text">Confirmo que estou acomodado em uma mesa</span>
					</label>
				</form>
			</ng-container>
		</div>
		<div class="terms">
			Ao confirmar o check-in, você está de acordo com o
			<span class="terms__btn" (click)="openTerms()"
				>Regulamento, Termos e Condições e Política de Privacidade.</span
			>
		</div>
	</div>

	<div class="page__footer">
		<div class="checkin__wrapper wrapper">
			<ng-container *ngIf="statusAvailability === 'success'">
				<button
					class="checkin__button button"
					(click)="confirmCheckin()"
					[ngClass]="{ 'button--disable': isDisableForm }"
					[disabled]="isDisableForm"
				>
					Check-in agora
					<app-spinner class="loading" *ngIf="statusConfirm === 'pending'"></app-spinner>
				</button>
			</ng-container>
			<ng-container *ngIf="statusAvailability === 'unavailable' || statusAvailability === 'failure'">
				<button class="checkin__button button" (click)="goToReservation()">Reservar uma mesa</button>
			</ng-container>
		</div>
	</div>
</div>

<app-modal [id]="modalsId.modalErrorConfirmCheckin" top="25%" [preventOutsideClickClose]="true">
	<app-modal-failure
		[id]="modalsId.modalErrorConfirmCheckin"
		template="somethingWentWrong"
		*ngIf="errorConfirm$ | async as error"
		[message]="error"
	></app-modal-failure>
</app-modal>

<app-modal [id]="modalsId.modalRegisterCard" top="25%" [preventOutsideClickClose]="true">
	<app-modal-register-card [id]="modalsId.modalRegisterCard"></app-modal-register-card>
</app-modal>

<app-modal [id]="modalsId.modalTerms" top="0%" bottom="0%" [preventOutsideClickClose]="false">
	<app-modal-terms [id]="modalsId.modalTerms"></app-modal-terms>
</app-modal>
