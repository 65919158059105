import { Component, Input, OnInit } from '@angular/core'
import { campaignService } from 'src/app/shared/services/campaign.service'

@Component({
	selector: 'app-benefit [value]',
	templateUrl: './benefit.component.html',
	styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent implements OnInit {
	@Input() value!: string
	@Input() size: 'default' | 'small' = 'default'
	@Input() text: 'default' | 'short' | 'dessert' = 'default'

	textPoints!: string
	doubleValue!: number // Promo em dobro

	classes = {
		small: 'benefit__text--small',
		default: '',
	}

	constructor(public readonly campaignService: campaignService) {}

	ngOnInit(): void {
		this.textPoints = Number(this.value) > 1 ? `${this.value} pontos` : `${this.value} ponto`
		this.doubleValue = 2 * Number(this.value) //Promo em dobro
	}

	get chooseSize() {
		return this.classes[this.size]
	}
}
